<template>
  <div>
    <Header :icons="[{ icon: FilterIcon, event: 'filter' }]" @filter="isShowFilterModal=true" />
    <Table :columns="columns" :data="list" />
    <Select :visible="isShowFilterModal" @confirm="onConfirm" />
  </div>
</template>

<script>
import { fetchFundReport } from '@/api/report';
import Table from '@/components/table';
import Header from '@/components/header/headerWithIcon';
import Select from '@/components/select/reports/balance';
import FilterIcon from '@/assets/images/common/filter-white.png';

export default {
  components: {
    Table,
    Header,
    Select,
  },
  data() {
    return {
      FilterIcon,
      isShowFilterModal: false,
      columns: [
        { name: '月份', props: 'month' },
        { name: '欠款金额', props: 'due' },
        { name: '还款金额', props: 'payBack' },
        { name: '账款金额', props: 'balance' },
      ],
      list: [],
    }
  },
  methods: {
    async onConfirm(params) {
      this.isShowFilterModal=false;
      const res = await fetchFundReport(params);
      this.list = res.data.map(item => {
        return {
          month: item.date,
          due: item.arrears,
          payBack: item.repayment,
          balance: item.amount,
        }
      });
    },
  }
}
</script>