<template>
  <Container :visible="visible" class="select-reports-termial" @confirm="onConfirm">
    <Row label="属地化人员" @click="isShowMembers=true">
      <span class="label">{{salesmanName || '请选择'}}</span>
    </Row>
    <Row label="渠道类型">
      <Radio :list="checkList" v-model="channel" />
    </Row>
    <Row label="选择终端" class="brand" @click="isShowTermialModal=true">
      <span class="label">{{terminalList[0] && terminalList[0].contactPerson || '请选择'}}</span>
    </Row>
    <Row label="选择种植大户" @click="isShowGrowerModal=true">
      <span class="label">{{growerList[0] && growerList[0].bigfarmersPerson || '请选择'}}</span>
    </Row>
    <div class="fixed-container" v-if="isShowMembers || isShowTermialModal || isShowGrowerModal">
      <SalesmanSelect 
        single
        selectType="salesman" 
        v-model="salesmanList" 
        @confirm="isShowMembers=false"
      />
      <TerminalSelect 
        selectType="terminal" 
        userType="ziyou"
        single
        v-model="terminalList"
        v-if="isShowTermialModal"
        @confirm="isShowTermialModal=false"
      />
      <GrowerSelect
        single
        selectType="grower" 
        v-model="growerList"
        v-if="isShowGrowerModal"
        @confirm="isShowGrowerModal=false"
      />
    </div>
  </Container>
</template>

<script>
import Row from "../row";
import Radio from "../radio";
import Container from "../container";
import TerminalSelect from '@/views/select/blockSelect';
import SalesmanSelect from '@/views/select/blockSelect';
import GrowerSelect from '@/views/select/blockSelect';

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Row,
    Radio,
    Container,
    GrowerSelect,
    SalesmanSelect,
    TerminalSelect,
  },
  data() {
    return {
      channel: '',
      salesmanList: [],
      terminalList: [],
      growerList: [],
      isShowGrowerModal: false,
      isShowMembers: false,
      isShowTermialModal: false,
      checkList: [
        { key: "1", value: "终端" },
        { key: "2", value: "种植大户" },
      ],
    };
  },
  computed: {
    salesmanId() {
      return this.salesmanList[0] && this.salesmanList[0].userId || '';
    },
    salesmanName() {
      return this.salesmanList[0] && this.salesmanList[0].nickName || '';
    },
    growerId() {
      return this.growerList[0] && this.growerList[0].bigfarmersId || '';
    }
  },
  mounted() {
    this.onConfirm();
  },
  methods: {
    onConfirm() {
      this.$emit('confirm', {
        id: this.salesmanId || this.growerId,
        type: this.channel,
        bussinessMan: this.salesmanName,
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.select-reports-termial {
  .brand {
    margin-top: 12px;
  }
  .label {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #cecece;
  }
}
</style>
